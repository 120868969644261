import firebaseDevelopment from './firebase-config.development';
import firebaseProduction from './firebase-config.production';

export const ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const firebase =
  ENV === 'production' ? firebaseProduction : firebaseDevelopment;

export const DB_ROOT_PATH = 'sites/kraftmusic-plus';
export const STORAGE_ROOT_PATH = 'sites/kraftmusic-plus';
